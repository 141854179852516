<template>
  <main class="login">
    <h1>
      프로젝트와 파트너 모두<br />
      IT 전문가가 직접 검증하고 연결 합니다.
    </h1>
    <div class="login_form b_form">
      <h3>Login</h3>
      <p>블루버튼에 가입한 아이디와 비밀번호를 입력하세요.</p>
      <form @submit.prevent="formSubmit">
        <ValidationProvider name="아이디" ref="refId" rules="required">
          <input
            type="id"
            name="id"
            v-model="id"
            placeholder="아이디"
            ref="refIdInput"
          />
        </ValidationProvider>
        <ValidationProvider name="비밀번호" ref="refPassword" rules="required">
          <input
            type="password"
            name="password"
            v-model="password"
            ref="refPasswordInput"
            placeholder="비밀번호"
          />
        </ValidationProvider>
        <button type="submit" :disabled="waitRequest">로그인</button>
      </form>
      <div class="login_registers register">
        <router-link to="/findid">아이디 찾기</router-link> |
        <router-link to="/findpw">비밀번호 찾기</router-link> |
        <router-link to="/signup">회원가입</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      waitRequest: false,
      id: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async formSubmit(evt) {
      evt.preventDefault();
      const refId = await this.$refs.refId.validate();
      if (!refId.valid) {
        alert(refId.errors[0]);
        this.$refs.refIdInput.focus();
        return false;
      }
      const refPassword = await this.$refs.refPassword.validate();
      if (!refPassword.valid) {
        alert(refPassword.errors[0]);
        this.$refs.refPasswordInput.focus();
        return false;
      }

      this.waitRequest = true;
      await this.$store
        .dispatch("login", {
          member_id: this.id,
          member_pw: this.password,
        })
        .then(() => {
          this.waitRequest = false;
        })
        .catch((response) => {
          alert(response.data.message);
          this.waitRequest = false;
        });
      return true;
    },
    async validation() {
      const refId = await this.$refs.refId.validate();
      if (!refId.valid) {
        alert(refId.errors[0]);
        this.$refs.refIdInput.focus();
        return false;
      }
      const refPassword = await this.$refs.refPassword.validate();
      if (!refPassword.valid) {
        alert(refPassword.errors[0]);
        this.$refs.refPasswordInput.focus();
        return false;
      }
      return true;
    },
  },
};
</script>
